
import { defineComponent, ref, onMounted, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
export default defineComponent({
  name: "edit-role",
  props: ["id"],
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    let objectData = ref({});
    const listDataPermissionHTML = ref();
    const dataPermissionCurrentRole = ref();
    onMounted(async () => {
      await store.dispatch("GET_PERMISSIONS_DATA", "");
      await store.dispatch("GET_ROLE_DETAIL", props.id);
      objectData.value = await store.getters["currentRole"];
      const listDataGetterPermissions = computed(
        () => store.getters["listDataPermissions"]
      );
      listDataPermissionHTML.value = JSON.parse(
        JSON.stringify(listDataGetterPermissions.value)
      ); // get all permissions
      dataPermissionCurrentRole.value = JSON.parse(
        JSON.stringify(objectData.value["permissions"])
      ); // get permissions current role
      listDataGetterPermissions.value.forEach(function (value, index) {
        // update when permission not exists permission roles
        if (dataPermissionCurrentRole[value._id] == undefined) {
          dataPermissionCurrentRole[value._id] = false;
        }
      });

      setCurrentPageBreadcrumbs("Cập nhật quyền", ["Người dùng"]);
      MenuComponent.reinitialization();
    });
    const validateSchema = Yup.object().shape({
      name: Yup.string().required("Quyền không được để trống"),
    });
    const onSubmitForm = (values) => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      values.permissions = dataPermissionCurrentRole.value;
      store
        .dispatch("UPDATE_ROLE", values)
        .then(() => {
          Swal.fire({
            text: "Cập nhật thông tin thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Danh sách",
            cancelButtonText: "Tiếp tục chỉnh sửa",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          }).then(function (result) {
            // Go to page after successfully login
            if (result.isConfirmed) {
              router.push({ name: "roles" });
            }
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
    };
    return {
      validateSchema,
      onSubmitForm,
      objectData,
      dataPermissionCurrentRole,
      listDataPermissionHTML,
    };
  },
});
